import Vue from "vue";
import VueRouter from "vue-router";
import DialogDigitalPeople from "../views/dialog-digital-people/index.vue";
import MobileDialogDigitalPeople from "../views/mobile-dialog-digital-people/index.vue";
import Steel from "../views/steel/index.vue";
import Culture from "../views/culture/index.vue";
import Medicine from "../views/medicine/index.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/steel",
    name: 'Steel',
    component: Steel,
    meta: {
      title: '数字钢铁平台能力体验'
    }
  },
  {
    path: "/culture",
    name: 'Culture',
    component: Culture,
    meta: {
      title: '西宁城北区文旅能力体验'
    }
  },
  {
    path: "/medicine",
    name: 'Medicine',
    component: Medicine,
    meta: {
      title: '青海新绿洲药业能力体验'
    }
  },
  {
    path: "/mobile",
    name: MobileDialogDigitalPeople,
    component: MobileDialogDigitalPeople,
    meta: {
      title: '新绿洲药业集团医药数字人'
    }
  },
  {
    path: "/",
    component: DialogDigitalPeople,
    meta: {
      title: '新绿洲药业集团医药数字人'
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
