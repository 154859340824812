var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{
      [_vm.style.mask]: true,
      [_vm.style.maskVisible]: true,
    }},[_c('div',{class:_vm.style.main},[_c('img',{class:_vm.style.closeBtn,attrs:{"src":require("../../assets/icons/close-panel.svg"),"alt":""},on:{"click":_vm.closePanel}}),_c('video',{class:{ [_vm.style.flvVideoVisible]: _vm.flvVideoVisible },attrs:{"id":"flv_container"}}),_c('video',{class:{ [_vm.style.bgVideoVisible]: _vm.bgVideoVisible },attrs:{"id":"bg_container","autoplay":"","muted":"","loop":"","src":"https://s3-xn.wair.ac.cn/metaman/metaman/background/73/luDRqTmy_bg.mp4"},domProps:{"muted":true}},[_vm._v(" Your browser does not support the video tag. ")]),_c('div',{class:_vm.style.messageBox},[_c('div',{ref:"messageList",class:_vm.style.messageList,on:{"scroll":_vm.handelScroll}},[_c('div',{class:_vm.style.scrollContent},[_vm._l((_vm.messageList),function(item,index){return _c('div',{key:index,class:_vm.style.singleMessageBox},[_c('div',{class:{
                  [_vm.style.timesAgo]: true,
                  [_vm.style.leftTimesAgo]: item.role === 'robot',
                  [_vm.style.rightTimesAgo]: item.role === 'user',
                }},[_vm._v(" "+_vm._s(item?.time)+" ")]),_c('div',{class:{
                  [_vm.style.robot]: item.role === 'robot',
                  [_vm.style.user]: item.role === 'user',
                  [_vm.style.message]: true,
                }},[_c('vue-markdown',{attrs:{"source":item?.message}})],1)])}),(_vm.loading)?_c('div',{class:_vm.style.singleMessageBox},[_c('div',{class:[_vm.style.timesAgo, _vm.style.leftTimesAgo]},[_vm._v("1分钟内")]),(this.answer)?_c('span',{class:[_vm.style.robot, _vm.style.message]},[_c('vue-markdown',{attrs:{"source":this.answer}})],1):_c('div',{class:[_vm.style.robot, _vm.style.message, _vm.style.dotContainer]},[_c('div',{class:_vm.style.dot1}),_c('div',{class:_vm.style.dot2}),_c('div',{class:_vm.style.dot3})])]):_vm._e()],2)]),_c('div',{staticClass:"flex",class:_vm.style.inputBox},[_c('img',{class:_vm.style.textMessage,attrs:{"src":require("../../assets/icons/text-message.svg"),"alt":"message"}}),_c('input',{ref:"messageInput",style:({ cursor: _vm.inputDisable ? 'not-allowed' : 'default' }),attrs:{"disabled":_vm.inputDisable,"type":"text","placeholder":"请输入问题，发起对话"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage.apply(null, arguments)},"focus":_vm.focus}}),_c('img',{class:_vm.style.sendBtn,style:({ cursor: _vm.inputDisable ? 'not-allowed' : 'pointer' }),attrs:{"src":require("../../assets/icons/medicine-send.svg"),"alt":"send"},on:{"click":_vm.sendMessage}})]),(_vm.warningTipVisible)?_c('div',{class:_vm.style.warnMessage},[_c('img',{attrs:{"src":require("../../assets/icons/warn-icon.svg"),"alt":""}}),_vm._v(_vm._s(this.serverErrMsg)+" ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }