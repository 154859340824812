<template>
  <div>
    <div
      :class="{
        [style.mask]: true,
        [style.maskVisible]: true,
      }"
    >
      <div :class="style.main">
        <img
          :class="style.bgImg"
          src="../../assets/images/steel-bg.png"
          alt=""
        />
        <div :class="style.messageBox">
          <div
            ref="messageList"
            @scroll="handelScroll"
            :class="style.messageList"
          >
            <div :class="style.scrollContent">
              <div
                v-for="(item, index) in messageList"
                :key="index"
                :class="style.singleMessageBox"
              >
                <div
                  :class="{
                    [style.robot]: item.role === 'robot',
                    [style.user]: item.role === 'user',
                    [style.message]: true,
                  }"
                >
                  <vue-markdown :source="item?.message"></vue-markdown>
                </div>
              </div>

              <div v-if="loading" :class="style.singleMessageBox">
                <span :class="[style.robot, style.message]" v-if="this.answer">
                  <vue-markdown :source="this.newAnswer"></vue-markdown>
                </span>
                <div
                  v-else
                  :class="[style.robot, style.message, style.dotContainer]"
                >
                  <div :class="style.dot1"></div>
                  <div :class="style.dot2"></div>
                  <div :class="style.dot3"></div>
                </div>
              </div>
            </div>
          </div>
          <div :class="style.inputBox" class="flex">
            <img
              :class="style.textMessage"
              src="../../assets/icons/text-message.svg"
              alt="message"
            />
            <input
              ref="messageInput"
              :disabled="inputDisable"
              @keydown.enter="sendMessage"
              type="text"
              :style="{ cursor: inputDisable ? 'not-allowed' : 'default' }"
              placeholder="请输入问题，发起对话"
            />
            <img
              :class="style.sendBtn"
              @click="sendMessage"
              :style="{ cursor: inputDisable ? 'not-allowed' : 'pointer' }"
              src="../../assets/icons/steel-send.svg"
              alt="send"
            />
          </div>
          <div v-if="warningTipVisible" :class="style.warnMessage">
            <img src="../../assets/icons/warn-icon.svg" alt="" />{{
              this.serverErrMsg
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { findModelMixin } from "../../plugins/model-mixin";
import style from "./index.module.css";

// 钢铁项目会话
export default {
  name: "Steel-page",
  mixins: [findModelMixin],
  components: {
    "vue-markdown": VueMarkdown,
  },
  data() {
    return {
      style,
      messageList: [
        {
          role: "robot",
          message:
            "西宁城北区数字钢铁平台智能助手，竭诚为您服务！",
        },
      ],
      chatUrl: "/chat/steel/chat_service/chat",
    };
  }
};
</script>
