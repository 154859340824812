<template>
  <div>
    <div
      :class="{
        [style.mask]: true,
        [style.maskVisible]: true,
      }"
    >
      <div :class="style.main">
        <img
          @click="closePanel"
          :class="style.closeBtn"
          src="../../assets/icons/close-panel.svg"
          alt=""
        />
        <video
          id="flv_container"
          :class="{ [style.flvVideoVisible]: flvVideoVisible }"
        ></video>
        <video
          id="bg_container"
          autoplay
          muted
          loop
          :class="{ [style.bgVideoVisible]: bgVideoVisible }"
          src="https://s3-xn.wair.ac.cn/metaman/metaman/background/73/luDRqTmy_bg.mp4"
        >
          Your browser does not support the video tag.
        </video>
        <div :class="style.messageBox">
          <div
            ref="messageList"
            @scroll="handelScroll"
            :class="style.messageList"
          >
            <div :class="style.scrollContent">
              <div
                v-for="(item, index) in messageList"
                :key="index"
                :class="style.singleMessageBox"
              >
                <div
                  :class="{
                    [style.timesAgo]: true,
                    [style.leftTimesAgo]: item.role === 'robot',
                    [style.rightTimesAgo]: item.role === 'user',
                  }"
                >
                  {{ item?.time }}
                </div>
                <div
                  :class="{
                    [style.robot]: item.role === 'robot',
                    [style.user]: item.role === 'user',
                    [style.message]: true,
                  }"
                >
                  <vue-markdown :source="item?.message"></vue-markdown>
                </div>
              </div>

              <div v-if="loading" :class="style.singleMessageBox">
                <div :class="[style.timesAgo, style.leftTimesAgo]">1分钟内</div>
                <span :class="[style.robot, style.message]" v-if="this.answer"
                  ><vue-markdown :source="this.answer"></vue-markdown
                ></span>
                <div
                  v-else
                  :class="[style.robot, style.message, style.dotContainer]"
                >
                  <div :class="style.dot1"></div>
                  <div :class="style.dot2"></div>
                  <div :class="style.dot3"></div>
                </div>
              </div>
            </div>
          </div>
          <div :class="style.inputBox" class="flex">
            <img
              :class="style.textMessage"
              src="../../assets/icons/text-message.svg"
              alt="message"
            />
            <input
              ref="messageInput"
              :disabled="inputDisable"
              @keydown.enter="sendMessage"
              @focus="focus"
              type="text"
              :style="{ cursor: inputDisable ? 'not-allowed' : 'default' }"
              placeholder="请输入问题，发起对话"
            />
            <img
              :class="style.sendBtn"
              @click="sendMessage"
              :style="{ cursor: inputDisable ? 'not-allowed' : 'pointer' }"
              src="../../assets/icons/medicine-send.svg"
              alt="send"
            />
          </div>
          <div v-if="warningTipVisible" :class="style.warnMessage">
            <img src="../../assets/icons/warn-icon.svg" alt="" />{{
              this.serverErrMsg
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import Srs from "../../assets/js/srs.sdk";
import style from "./index.module.css";

// 体验数字人弹窗
export default {
  name: "DialogDigitalPeople",
  components: {
    "vue-markdown": VueMarkdown,
  },
  data() {
    return {
      style,
      flvVideoVisible: false,
      bgVideoVisible: true,
      socket: null,
      loading: false,
      inputDisable: true,
      answer: "",
      messageList: [],
      serverErrMsg: "服务异常，请稍后重试",
      autoScrollBottom: true, // 聊天窗口的内容是否需要自动滚动到底部,
      warningTipVisible: false, // 提示框状态
    };
  },
  watch: {
    "messageList.length"(newVal, oldVal) {
      if (newVal > oldVal) {
        this.messageList = this.messageList.map((item) => {
          return {
            ...item,
            time: this.timesAgo(item.timeStamp),
          };
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initWebsocketConnect();
    });
  },
  methods: {
    closePanel() {
      console.log("closePanel");
    },
    initState() {
      this.flvVideoVisible = false;
      this.bgVideoVisible = true;
      this.loading = false;
      this.inputDisable = true;
      this.autoScrollBottom = true;
      this.answer = "";
      this.messageList = [];
      this.warningTipVisible = false;
      if (this.socket) {
        this.socket.close();
      }
    },
    initWebsocketConnect() {
      const wsToken =
        "dU5ROWszTkE1MGp2V2VlbnlwN0JIdUR5enpzcFVyQjdZSGllcnE5Umg1R0tBQWpLMjBTZ2hQeU1MOU5uSGNTMWFmc1lsUDBTNHh6YjgzVVZHbXMzdXc9PQ";
      const websocketUrl = `wss://metahuman-xn.wair.ac.cn/metaman/ws/server/show?ws_token=${wsToken}&addr=`;
      this.socket = new WebSocket(websocketUrl);
      this.socket.onopen = () => {
        const message = {
          type: "action",
          name: "start",
          params: {
            sessionId: this.generateSessionId(),
            first_entry: 1,
          },
        };
        this.socket.send(JSON.stringify(message));
      };
      this.socket.onmessage = (e) => {
        try {
          const wsData = JSON.parse(e?.data);
          const { name, has_next, data } = wsData;
          if (name === "start" && data?.data?.httpflv_live_address) {
            this.webrtcMethod(wsData?.data?.data?.webrtc_live_address);
          } else if (name === "show" && has_next) {
            this.answer += data?.data?.answer;
          } else if (name === "show" && !has_next) {
            this.messageList.push({
              role: "robot",
              message: this.answer || data?.data?.answer || this.serverErrMsg,
              timeStamp: new Date().getTime(),
              time: "1分钟内",
            });
            this.loading = false;
            this.answer = "";
            this.inputDisable = false;
            this.flvVideoVisible = false;
            this.bgVideoVisible = true;
            this.warningTipVisible = false;
          } else {
            if (
              wsData?.data?.code !== 0 &&
              wsData?.data?.message !== "success"
            ) {
              console.log(wsData);
              this.errorHandler();
            }
          }
          this.scrollToBottom();
        } catch (error) {
          console.log(error);
          this.errorHandler();
        }
      };
      this.socket.addEventListener("error", (event) => {
        console.log("WebSocket error: ", event);
        this.errorHandler("perceivable");
      });
    },
    // 向数字人发送消息
    sendMessage() {
      if (this.inputDisable) return;
      const message = this.$refs.messageInput.value;
      const params = {
        type: "interface",
        name: "show",
        params: {
          text: message,
          type: "text",
        },
      };
      this.messageList.push({
        role: "user",
        message,
        timeStamp: new Date().getTime(),
        time: "1分钟内",
      });
      this.socket.send(JSON.stringify(params));
      this.autoScrollBottom = true;
      this.$nextTick(() => {
        this.scrollToBottom();
      });
      this.loading = true;
      this.inputDisable = true;
      this.$refs.messageInput.value = "";
      this.flvVideoVisible = true;
      this.bgVideoVisible = false;
    },
    // 初始化数字人直播链接
    webrtcMethod(url) {
      const flv_container = document.getElementById("flv_container");
      if (flv_container) {
        const rtcPlayer = new Srs.SrsRtcPlayerAsync();
        rtcPlayer?.play(url);
        // video标签
        flv_container.srcObject = rtcPlayer.stream;
        console.log("数字人初始化成功");
        this.inputDisable = false;
      } else {
        console.log("数字人初始化失败");
        this.errorHandler();
      }
    },
    handelScroll() {
      // 监听消息列表滚动
      // 当滚动到底部后，后续新增消息需要自动滚动到底部
      // 当滚动到其他位置，后续新增消息不需要自动滚动到底部
      const { scrollTop, offsetHeight, scrollHeight } = this.$refs.messageList;
      if (scrollTop + offsetHeight + 2 >= scrollHeight) {
        if (!this.autoScrollBottom) this.autoScrollBottom = true;
      } else if (this.autoScrollBottom) {
        this.autoScrollBottom = false;
      }
    },
    // 只要有新消息，消息列表就要滚到底部
    scrollToBottom() {
      if (this.autoScrollBottom) {
        const messageList = this.$refs.messageList;
        messageList.scrollTop = messageList.scrollHeight;
      }
    },
    // sessionId为不重复的字符串，格式类似uuid
    generateSessionId() {
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    /**
     * 数字人服务出错后的异常处理
     * 错误分为两类：
     * 1.用户可感知错误，如网络错误，通过对话列表形式展示
     * 2.用户不可感知错误，通过提示框展示
     * @param type
     */
    errorHandler(type) {
      if (type === "perceivable") {
        this.messageList.push({
          role: "robot",
          message: "非常抱歉，网络错误请稍后重试",
          timeStamp: new Date().getTime(),
          time: "1分钟内",
        });
      } else {
        this.warningTipVisible = true;
      }
    },
    timesAgo(time) {
      let data = new Date(time);
      let dateTimeStamp = data.getTime();
      let minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      let year = month * 12;
      let now = new Date().getTime(); //获取当前时间毫秒
      let diffValue = now - dateTimeStamp; //时间差

      let result = "";
      if (diffValue < 0) {
        result = "" + "未来";
      }
      let minC = diffValue / minute; //计算时间差的分，时，天，周，月
      let hourC = diffValue / hour;
      let dayC = diffValue / day;
      let weekC = diffValue / week;
      let monthC = diffValue / month;
      let yearC = diffValue / year;

      if (yearC >= 1) {
        result = parseInt(yearC) + "年前";
      } else if (monthC >= 1 && monthC < 12) {
        result = parseInt(monthC) + "月前";
      } else if (weekC >= 1 && weekC < 5 && dayC > 6 && monthC < 1) {
        result = parseInt(weekC) + "周前";
      } else if (dayC >= 1 && dayC <= 6) {
        result = parseInt(dayC) + "天前";
      } else if (hourC >= 1 && hourC <= 23) {
        result = parseInt(hourC) + "小时前";
      } else if (minC >= 1 && minC <= 59) {
        result = parseInt(minC) + "分钟前";
      } else if (diffValue >= 0 && diffValue <= minute) {
        result = "1分钟内";
      }

      return result;
    },
    focus() {
      const flv_container = document.getElementById("flv_container");
      if (flv_container) {
        flv_container.play();
      }
    },
  },
};
</script>
