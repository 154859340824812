/**
 * 找模型模块的公共代码部分
 */
export const findModelMixin = {
  data() {
    return {
      baseUrl: "",
      sessionId: null,
      loading: false,
      inputDisable: false,
      answer: "",
      messageList: [],
      serverErrMsg: "服务异常，请稍后重试",
      autoScrollBottom: true, // 聊天窗口的内容是否需要自动滚动到底部,
      warningTipVisible: false, // 提示框状态
      urlPattern: /(https?:\/\/[^\s/$.?#].[^\s]*)/g,
    };
  },
  computed: {
    newAnswer: function () {
      const urls = this.answer.match(this.urlPattern);
      if (urls?.length > 0) {
        const str = this.answer?.replace(/\(https/g,'https');        
        return str?.replace(this.urlPattern, function () {
          return  `<a href='${urls[arguments[1]]}' target="_blank"></a>`;
        });
      } else {
        return this.answer;
      }
    },
  },
  methods: {
    formatUrl(message) {
      const urls = message.match(this.urlPattern);
      if (urls?.length > 0) {
        return message?.replace(this.urlPattern, function () {
          return `[【点我导航】](${urls[arguments[1]]} "点我导航")`;
        });
      } else {
        return message;
      }
    },
    // sessionId为不重复的字符串，格式类似uuid
    generateSessionId() {
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    // 向数字人发送消息
    sendMessage(text) {
      if (this.inputDisable) return;
      let message = text;
      if (typeof text !== "string") {
        message = this.$refs.messageInput.value || "";
      }
      this.messageList.push({
        role: "user",
        message,
        timeStamp: new Date().getTime(),
        time: "1分钟内",
      });
      this.autoScrollBottom = true;
      this.$nextTick(() => {
        this.scrollToBottom();
      });
      this.loading = true;
      this.inputDisable = true;
      this.$refs.messageInput.value = "";
      this.handelChat(message);
    },
    async handelChat(message) {
      const { baseUrl, sessionId, chatUrl } = this;
      let requestUrl = `${baseUrl}${chatUrl}`;
      try {
        // 发送请求
        let response = await fetch(requestUrl, {
          method: "post",
          responseType: "stream",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            session_id: sessionId,
            input: message,
          }),
        });
        // ok字段判断是否成功获取到数据流
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // 用来获取一个可读的流的读取器（Reader）以流的方式处理响应体数据
        const reader = response.body.getReader();
        // 将流中的字节数据解码为文本字符串
        const textDecoder = new TextDecoder();
        let result = true;
        let buffer = "";
        while (result) {
          // done表示流是否已经完成读取  value包含读取到的数据块
          const { done, value } = await reader.read();
          if (done) {
            result = false;
            this.messageList.push({
              role: "robot",
              message: this.answer || this.serverErrMsg,
              timeStamp: new Date().getTime(),
              time: "1分钟内",
            });
            this.loading = false;
            this.answer = "";
            this.inputDisable = false;
            this.warningTipVisible = false;
            this.scrollToBottom();
            break;
          }
          // 需要通过decode方法处理数据块，例如转换为文本或进行其他操作
          textDecoder
            .decode(value)
            .split("\n")
            .forEach((val) => {
              if (!val) return;
              buffer += val;
              try {
                const { output } = JSON.parse(buffer)?.data;
                this.answer += output[0];
                buffer = "";
                this.scrollToBottom();
              } catch (error) {
                console.log("value:", val);
                console.log(error);
              }
            });
        }
      } catch (err) {
        console.log(err);
      }
    },
    handelScroll() {
      // 监听消息列表滚动
      // 当滚动到底部后，后续新增消息需要自动滚动到底部
      // 当滚动到其他位置，后续新增消息不需要自动滚动到底部
      const { scrollTop, offsetHeight, scrollHeight } = this.$refs.messageList;
      if (scrollTop + offsetHeight + 25 >= scrollHeight) {
        if (!this.autoScrollBottom) this.autoScrollBottom = true;
      } else if (this.autoScrollBottom) {
        this.autoScrollBottom = false;
      }
    },
    // 只要有新消息，消息列表就要滚到底部
    scrollToBottom() {
      if (this.autoScrollBottom) {
        const messageList = this.$refs.messageList;
        messageList.scrollTop = messageList.scrollHeight;
      }
    },
    /**
     * 数字人服务出错后的异常处理
     * 错误分为两类：
     * 1.用户可感知错误，如网络错误，通过对话列表形式展示
     * 2.用户不可感知错误，通过提示框展示
     * @param type
     */
    errorHandler(type) {
      if (type === "perceivable") {
        this.messageList.push({
          role: "robot",
          message: "非常抱歉，网络错误请稍后重试",
          timeStamp: new Date().getTime(),
          time: "1分钟内",
        });
      } else {
        this.warningTipVisible = true;
      }
    },
  },
  created() {
    const href = window.location.href;
    if (href.indexOf("8080") > -1) {
      this.baseUrl = "http://101.201.49.105:9092";
    } else {
      this.baseUrl = "http://101.201.49.105:9092";
    }
    this.sessionId = this.generateSessionId();
  },
};
