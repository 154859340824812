var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{
      [_vm.style.mask]: true,
      [_vm.style.maskVisible]: true,
    }},[_c('div',{class:_vm.style.main},[_c('img',{class:_vm.style.bgImg,attrs:{"src":require("../../assets/images/steel-bg.png"),"alt":""}}),_c('div',{class:_vm.style.messageBox},[_c('div',{ref:"messageList",class:_vm.style.messageList,on:{"scroll":_vm.handelScroll}},[_c('div',{class:_vm.style.scrollContent},[_vm._l((_vm.messageList),function(item,index){return _c('div',{key:index,class:_vm.style.singleMessageBox},[_c('div',{class:{
                  [_vm.style.robot]: item.role === 'robot',
                  [_vm.style.user]: item.role === 'user',
                  [_vm.style.message]: true,
                }},[_c('vue-markdown',{attrs:{"source":item?.message}})],1)])}),(_vm.loading)?_c('div',{class:_vm.style.singleMessageBox},[(this.answer)?_c('span',{class:[_vm.style.robot, _vm.style.message]},[_c('vue-markdown',{attrs:{"source":this.newAnswer}})],1):_c('div',{class:[_vm.style.robot, _vm.style.message, _vm.style.dotContainer]},[_c('div',{class:_vm.style.dot1}),_c('div',{class:_vm.style.dot2}),_c('div',{class:_vm.style.dot3})])]):_vm._e()],2)]),_c('div',{staticClass:"flex",class:_vm.style.inputBox},[_c('img',{class:_vm.style.textMessage,attrs:{"src":require("../../assets/icons/text-message.svg"),"alt":"message"}}),_c('input',{ref:"messageInput",style:({ cursor: _vm.inputDisable ? 'not-allowed' : 'default' }),attrs:{"disabled":_vm.inputDisable,"type":"text","placeholder":"请输入问题，发起对话"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage.apply(null, arguments)}}}),_c('img',{class:_vm.style.sendBtn,style:({ cursor: _vm.inputDisable ? 'not-allowed' : 'pointer' }),attrs:{"src":require("../../assets/icons/steel-send.svg"),"alt":"send"},on:{"click":_vm.sendMessage}})]),(_vm.warningTipVisible)?_c('div',{class:_vm.style.warnMessage},[_c('img',{attrs:{"src":require("../../assets/icons/warn-icon.svg"),"alt":""}}),_vm._v(_vm._s(this.serverErrMsg)+" ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }